import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.#hideLoadingOverlay();
  }

  #hideLoadingOverlay() {
    this.element.classList.add('hidden');
  }
}
